<!-- eslint-disable max-len */ -->
<template>
  <section class="tabs-section">
    <Reminder v-if="!editmode && !tlte" class="rembutt" />
    <div v-if="loading" style="margin: 1rem 7rem">
      <b-skeleton height="500px" width="100%" :animated="true"></b-skeleton>
    </div>
    <b-tabs v-else class="tab tab-custom" :class="editmodeClass">
      <b-tab-item :label="courseTypeLabel" class="" icon="information-outline">
        <section>
          <div class="">
            <vue-editor v-if="editmode" v-model="curr_course.tab_desc"
                :editor-toolbar="customToolbar">
            </vue-editor>
            <span v-else v-html="curr_course.tab_desc"></span>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item v-if="!tlte && !mindtrigger && !live" id="qc" :label="$t('course.course-tabs.labels.qc')"
      icon="checkbox-blank-circle-outline">
        <Upload v-if="editmode" v-model="qc_pdf" :mimetype="'image/*, application/pdf'"
        :multi="true" @input="upload('qc')" :custom="'qc'"/>
        <QuickCards v-if="!reload_qc" :editmode="editmode" />
      </b-tab-item>
      <b-tab-item v-if="showTab"
        id="resources" :label="$t('course.course-tabs.labels.resources')" icon="file">
        <b-table ref="table"
        class="table"
        :data="unlocked_resources"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        default-sort="lastname"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">
          <b-table-column field="title" label="Dateiname" sortable
          v-slot="props">
            <p v-if="!editmode">{{ props.row.name }}</p>
            <b-field v-else>
              <b-input type="text" :value="props.row.name"
              v-model="props.row.name"></b-input>
            </b-field>
          </b-table-column>
          <b-table-column v-if="!live" field="unlock" label="Verfügbar ab Trainingseinheit" sortable
          v-slot="props">
            <p v-if="!editmode">{{ props.row.unlock }}</p>
            <b-field v-if="editmode">
              <b-input type="number" placeholder="Freischalten ab Trainingseinheit ..."
              v-model="props.row.unlock"></b-input>
            </b-field>
          </b-table-column>
          <b-table-column label="Download" v-slot="props">
            <span class="mx-4" v-if="editmode" @click="deleteResource(props.row)">
              <font-awesome-icon class="icon" :icon="['fa', 'trash']" />
            </span>
            <span class="mx-4" @click="download(props.row.path, props.row.name, props.row.mimetype)">
              <font-awesome-icon class="icon" :icon="['fa', 'download']" />
            </span>
            <span class="mx-4" v-if="props.row.mimetype === 'pdf'" @click="viewPDF(props.row.path)">
              <font-awesome-icon class="icon" :icon="['fa', 'eye']" />
            </span>
          </b-table-column>
        </b-table>
          <b-upload v-if="editmode" @input="upload('resources')" v-model="resources"
          :multiple="true" drag-drop :type="'is-white'"
          expanded>
          <section class="section file-section">
            <div class="upload-block has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large">
                </b-icon>
              </p>
              <p>Dateien hier ablegen oder klicken</p>
            </div>
          </section>
        </b-upload>
      </b-tab-item>
      <b-tab-item v-if="!editmode && !tlte && !live" :label="$t('course.course-tabs.labels.diary')" icon="book-open-variant">
        <Diary :firstMP3="firstMP3" />
      </b-tab-item>
      <b-tab-item v-if="this.$store.getters['Course/has_check']"
          label="Auswertungen" icon="playlist-check">
        <div v-if="!show_pdf" class="container vanishIn">
          <h5>Schließen Sie die Vorabanalyse ab, um die erste Auswertung zu sehen.</h5>
        </div>
        <div v-if="show_pdf" class="has-text-centered vanishIn">
          <a :href="get_pdf" target="_blank">
            <img src="/img/check/download-pdf-3660827_640.png" width="200px">
          </a>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
/* eslint-disable max-len */

import axios from 'axios';

import Upload from '../Common/Upload.vue';
import Reminder from './Reminder.vue';
import QuickCards from './QuickCards.vue';
import Diary from './Diary.vue';

export default {
  components: {
    Upload,
    Reminder,
    QuickCards,
    Diary,
  },

  watch: {
    curr_course: {
      handler(newVal) {
        if (newVal) {
          this.setFirstMP3();
        }
      },
      immediate: true, // make this watch function is called when component created
    },
  },

  data() {
    return {
      firstMP3: '',
      qc_pdf: [],
      resources: [],
      reload_qc: false,
      reload_res: false,
      customToolbar: [
        [{ header: [false, 1, 2] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }],
      ],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
    };
  },
  methods: {
    setFirstMP3() {
      let u;
      if (this.$store.getters['Course/has_check']) {
        u = 1;
      } else {
        u = 0;
      }
      const courseName = this.curr_course.name.replace(/ /g, '_');
      const url = `https://gcvidpt.s3.amazonaws.com/asb/${courseName}_complete_unit_${u}.mp3`;
      fetch(url, { method: 'HEAD' })
        .then((response) => {
          if (response.ok) {
            this.firstMP3 = url;
          } else this.firstMP3 = false;
        });
    },
    async upload(type) {
      let data = {};
      let res = {};
      if (type === 'qc') {
        data = this.qc_pdf;
        this.reload_qc = true;
        res = await this.$store.dispatch('Uploads/upload_qc_pdf', data);
      }
      if (type === 'resources') {
        data.array = this.resources;
        data.course_id = this.curr_course.id;
        this.reload_resources = true;
        res = await this.$store.dispatch('Uploads/upload_res', data);
      }
      if (res) {
        this.curr_course.resources = res;
        this.resources = [];
        this.$buefy.toast.open({
          message: 'Dateien hochgeladen!',
          type: 'is-success',
        });
        this.reload_qc = false;
        this.reload_resources = false;
      }
    },
    viewPDF(url) {
      window.open(url, '_blank');
    },
    async deleteResource(resource) {
      const payload = {
        course_id: this.curr_course.id,
        path: resource.path,
      };
      this.$buefy.dialog.confirm({
        title: 'Datei löschen',
        message: `Soll die Datei ${resource.name} aus den Ressourcen entfernt werden?`,
        confirmText: 'Ja',
        cancelText: 'Nein',
        type: '',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('Course/deleteResource', payload).then((response) => {
            if (response) {
              // hier kommt noch ein falscher response,
              // deswegen manuell gelöscht, nicht ersetzt durch response
              this.curr_course.resources = this.curr_course.resources.filter((r) => r.path !== resource.path);
              this.$buefy.toast.open({
                message: 'Datei gelöscht!',
                type: 'is-success',
              });
            } else {
              this.$buefy.toast.open({
                message: 'Datei konnte nicht gelöscht werden.',
                type: 'is-danger',
              });
            }
          });
        },
      });
    },
    forceFileDownload(response, title, ext) {
      const name = `${title}.${ext}`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    },
    download(url, title, ext) {
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.forceFileDownload(response, title, ext);
        })
        .catch();
    },
  },
  computed: {
    loading() {
      return this.$store.getters['Course/loading'];
    },
    type() {
      return this.$store.getters['Course/type'];
    },
    editmodeClass() {
      if (!this.editmode) return 'tab tab-custom responsive-pad';
      return 'tab tab-custom';
    },
    mindtrigger() {
      if (this.type) {
        return this.type === 'mindtrigger';
      }
      return false;
    },
    live() {
      return this.type === 'live';
    },
    tlte() {
      if (this.type) {
        return (this.type === 'tl' || this.type === 'te');
      }
      return false;
    },
    showTab() {
      if (this.editmode && !this.reload_res) {
        return true;
      }
      return this.curr_course.master_id.resources && this.curr_course.master_id.resources.length > 0;
    },
    courseTypeLabel() {
      if (this.tlte) return this.$t('course.course-tabs.labels.tlte');
      if (this.mindtrigger) return this.$t('course.course-tabs.labels.mindtrigger');
      return this.$t('course.course-tabs.labels.default');
    },
    curr_course() {
      return this.$store.getters['Course/curr_course'];
    },
    unlocked_resources() {
      let allResources;
      if (!this.editmode) allResources = this.curr_course.master_id.resources;
      else allResources = this.curr_course.resources;
      if (allResources) {
        return allResources.filter((resources) => {
          if (this.editmode || this.live) {
            return resources;
          }
          const data = resources.unlock <= this.curr_course.curr_unit;
          return data;
        });
      }
      return [];
    },
    editmode() {
      if (this.$store.getters['Course/status'] < 10) {
        return true;
      }
      return false;
    },
    show_pdf() {
      const u = this.$store.getters['Course/curr_unit'];
      const p = this.$store.getters['Course/curr_page_nr'];

      if (u > 0 || (u === 0 && p > 1)) {
        return true;
      }
      return false;
    },
    get_pdf() {
      return this.$store.getters['User/get_pdf'];
    },
  },
};
</script>

<style lang="scss" theme="a">

.tabs-section {
  padding-top: 1rem;

  .icon {
    cursor: pointer;
    font-size: 1.3rem;
  }

  @media screen and (max-width: 1000px) {
    li > a > span:not(.icon) {
      visibility: hidden;
      position:  absolute;
    }
    nav.tabs li:not(.is-active) {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 1;
      flex-shrink: 1;
    }
    .tabs .icon {
      margin-left: 0.5em;
    }
  }
  h5 {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 8rem;
  }

  li {
    transition: flex-grow 1s ease;
  }

  h1 {
    font-family: "Noto Sans";
    color: black;
    font-size: 2.3rem;
    @media only screen and (max-width: 768px){
      font-size: 1.5rem;
    }
    padding-bottom: 1.5rem;
  }

  h2 {
    font-family: "Noto Sans";
    color: black;
    font-size: 1.5rem;
    @media only screen and (max-width: 768px){
      font-size: 1.2rem;
    }
    padding-bottom: 1rem;
  }

  p {
    font-family: "Noto Sans";
    font-weight: 350;
    padding-right: 2rem;
    padding-bottom: 2rem;
    @media only screen and (max-width: 600px) {
      font-size: .9rem;
      padding: 0;
      padding-bottom: 2rem;
    }
  }

  h3 {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
    font-size: 1.4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @media only screen and (max-width: 600px) {
    font-size: 1rem;
    }
  }

  .vanishIn {
    animation-name: vanishIn;
    animation-duration: 2s;
  }

  @-webkit-keyframes vanishIn {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(2, 2);
      filter: blur(90px);
    }
    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
      filter: blur(0px);
    }
  }

  @keyframes vanishIn {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(2, 2);
      filter: blur(90px);
    }
    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
      filter: blur(0px);
    }
  }
}
</style>

<style lang="scss" theme="b">

.tabs-section {
  padding-top: 1rem;

  @media screen and (max-width: 1000px) {
    li > a > span:not(.icon) {
      visibility: hidden;
      position:  absolute;
    }
    nav.tabs li:not(.is-active) {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 1;
      flex-shrink: 1;
    }
    .tabs .icon {
      margin-left: 0.5em;
    }
  }
  h5 {
    font-weight: 450;
    color: black;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 8rem;
  }

  li {
    transition: flex-grow 1s ease;
  }

  h1 {
    color: black;
    font-size: 2.3rem;
    @media only screen and (max-width: 768px){
      font-size: 1.5rem;
    }
    padding-bottom: 1.5rem;
  }

  h2 {
    color: black;
    font-size: 1.5rem;
    @media only screen and (max-width: 768px){
      font-size: 1.2rem;
    }
    padding-bottom: 1rem;
  }

  p {
    font-weight: 350;
    padding-right: 2rem;
    padding-bottom: 2rem;
    @media only screen and (max-width: 600px) {
      font-size: .9rem;
      padding: 0;
      padding-bottom: 2rem;
    }
  }
}
</style>
