<template>
  <section class="vid-sec">
      <div class="video" v-if="!editmode" >
        <video v-on:ended="p_ended()"
          ref="video" class="video"
          :src="vpath" :poster="whichPoster" controls>
        </video>
      </div>
      <div v-if="editmode" class="notification">
        <span v-if="editmode" @click="del_page()" class="del">
          <font-awesome-icon class="icon red" :icon="['fas', 'trash']" />
        </span>
        <div class="video">
          <video v-on:ended="p_ended()" ref="video"
          :src="vpath" :poster="whichPoster" controls>
          </video>
        </div>
        <input class="input link-input" v-model="page.content"
        :placeholder="$t('course.cvid.video-edit')">
        <!--<Upload class="upload" v-model="video" @input="uploadVideo($event, payload)"
          :mimetype="'video/*'" :multi="false" /> -->
        <b-field label="Video Poster">
          <input class="input link-input" v-model="page.poster"
            :placeholder="$t('course.cvid.poster-edit')">
          <Upload class="upload" v-model="poster" @input="uploadPoster($event, payload)"
          :mimetype="'image/*'" :multi="false" />
        </b-field>
        <b-field v-if="editmode">
          <b-switch v-model="page.note_activated">
            {{ $t('course.deactivate-notes') }}
          </b-switch>
        </b-field>
      </div>
  </section>
</template>

<script>
import Upload from '@/traincom/components/Common/Upload.vue';

export default {
  props: ['page', 'editmode', 'page_nr', 'unit_nr', 'audio'],

  components: {
    Upload,
  },

  data() {
    return {
      poster: [],
      video: [],
    };
  },

  computed: {
    vpath() {
      if (this.audio) {
        return this.page.content.replace(/\.mp4$/, '.mp3');
      }
      return this.page.content;
      // return this.page.content.replace('gcvidpt.s3.amazonaws.com', 'gcvids-1faf1.kxcdn.com');
    },
    curr_course() {
      return this.$store.getters['Course/curr_course'];
    },
    curr_page_nr() {
      return this.$store.getters['Course/curr_page_nr'];
    },
    curr_unit() {
      return this.$store.getters['Course/curr_unit'];
    },
    whichPoster() {
      if (this.curr_course.master_id) {
        if (this.page_nr !== undefined) {
          return this.curr_course.master_id.units[this.unit_nr].pages[this.page_nr].poster;
        }
        return this.curr_course.master_id.units[this.curr_unit].pages[this.curr_page_nr].poster;
      }
      return this.page.poster;
    },
  },

  methods: {
    play() {
      this.$refs.video.play();
    },
    p_ended() {
      this.$emit('ended');
    },
    del_page() {
      this.$emit('del_page');
    },
    showMsg(msg) {
      this.$buefy.snackbar.open({
        position: 'is-top-right',
        type: 'is-primary',
        message: msg,
      });
    },
    async uploadPoster(poster) {
      const res = await this.$store.dispatch('Uploads/upload_poster', poster);
      this.page.poster = res.data;
    },
    async uploadVideo() {
      const res = await this.$store.dispatch('Uploads/upload_video', this.video);
      this.page.video = res.data;
    },
  },
};
</script>

<style lang="scss">
.vid-sec {

  .video {
    margin-top: 0.9em;
  }

  .icon {
    font-size: 2rem;
    margin-right: 1rem;
    transition: all .3s;
    :hover {
      cursor: pointer;
      transform: scale(105%);
      transition: all .3s;
    }
  }

  .notification {
    padding: 1rem 1.5rem;
  }

  .upload {
    display: block;
    margin: .5rem;
  }

  .red {
    color: red;
  }

  .del {
    float: right;
  }

  // editmode
  .link-input {
    margin: 1rem 0;
  }
  .notification {
    height: 100%;
    margin-bottom: 1rem;
  }
}
</style>
